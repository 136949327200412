import React, {useState, useEffect} from 'react'
import './flour.css'
import flour1 from '../../../assets/flour/floor1.png'
import flour2 from '../../../assets/flour/floor2.png'
import flour3 from '../../../assets/flour/floor3.png'
import flour4 from '../../../assets/flour/floo4.png'
import Aos from 'aos'
import 'aos/dist/aos.css'
import {useTranslation} from 'react-i18next'

export default function Floor() {
  const {t} = useTranslation()

  const arr = [{
    src: flour1,
    name: t("Flour1"),
    sort: t("Flour_high"),
    description: t("Flour_high_desc")
  },
  {
    src: flour2,
    name: t("Flour2"),
    sort: t("Flour_first"),
    description: t("Flour_first_desc")
  },
  {
    src: flour3,
    name: t("Flour3"),
    sort: t("Twice_flour"),
    description: t("Flour_second_desc")
  },
  {
    src: flour4,
    name: t("Flour4"),
    sort: t("Third_flour"),
    description: t("Flour_peeled_desc")
  }]
  const [mass, setMass] = useState([
    
  ])

  useEffect(() => {
    Aos.init()
    setMass(arr)
  }, [mass]);

  return (
    <div className='big_content'>
      <div className="container pb-5">
        {
          (mass) && mass.map((item, index) => {
            return (
              <div className='row align-items-center content mt-5 '>
                <div  data-aos-duration='800' data-aos='fade-up' className="col-lg-4 col-12 p-4">
                  <img style={{ width: '100%', height: '100%' }} src={item.src} alt="flou1" />
                </div>
                <div  data-aos-duration='800' data-aos='fade-down' className='col-lg-8 col-12'>
                  <h2>{item.name}</h2>
                  <p>{item.sort}</p>
                  <p className='fw-lighter'>
                    {item.description}
                  </p>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}