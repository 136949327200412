import React from 'react';
import img from '../../assets/HOmeImage/1.png'
import Header from '../../components/ui/ProductHeader'
import img1 from '../../assets/oli/oil.png'
import Foss from '../../components/ui/Foss/Foss'



const Index = () => {
    const styles = {
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    };
    return (
        <div style={styles}>
            <Header img={img1} text='Our laboratory' />
            <Foss/>
        </div>
    );
}

export default Index;
