import React, { useState, useEffect } from 'react'
import './contact.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import {useTranslation} from 'react-i18next'

export default function Contact() {
  const {t} = useTranslation()
  const [mass, setMass] = useState([]);
  const arr =     [
    {
      name: t("Contact_name1"),
      number: t("Contact_number1"),
      address: t("Contact_address1"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d102361.59430555941!2d67.07788207923123!3d36.70334791941662!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f34b8a3605314bd%3A0xb27a5e1a6c1fcf71!2z0JzQsNC30LDRgNC4LdCo0LDRgNC40YQsINCQ0YTQs9Cw0L3QuNGB0YLQsNC9!5e0!3m2!1sru!2s!4v1678117060414!5m2!1sru!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name2"),
      number: t("Contact_number2"),
      address: t("Contact_address2"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d420605.9923280171!2d68.91753733270832!3d34.55395251583596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38d1694c3c1e6d49%3A0xebdf473578214429!2z0JrQsNCx0YPQuywg0JDRhNCz0LDQvdC40YHRgtCw0L0!5e0!3m2!1sru!2s!4v1678116650390!5m2!1sru!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name3"),
      number: t("Contact_number3"),
      address: t("Contact_address3"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d420605.9923280171!2d68.91753733270832!3d34.55395251583596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38d1694c3c1e6d49%3A0xebdf473578214429!2z0JrQsNCx0YPQuywg0JDRhNCz0LDQvdC40YHRgtCw0L0!5e0!3m2!1sru!2s!4v1678116650390!5m2!1sru!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name4"),
      number: t("Contact_number4"),
      address: t("Contact_address4"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d420605.9923280171!2d68.91753733270832!3d34.55395251583596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38d1694c3c1e6d49%3A0xebdf473578214429!2z0JrQsNCx0YPQuywg0JDRhNCz0LDQvdC40YHRgtCw0L0!5e0!3m2!1sru!2s!4v1678116650390!5m2!1sru!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name5"),
      number: t("Contact_number5"),
      address: t("Contact_address5"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d420605.9923280171!2d68.91753733270832!3d34.55395251583596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38d1694c3c1e6d49%3A0xebdf473578214429!2z0JrQsNCx0YPQuywg0JDRhNCz0LDQvdC40YHRgtCw0L0!5e0!3m2!1sru!2s!4v1678116650390!5m2!1sru!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name6"),
      number: t("Contact_number6"),
      address: t("Contact_address6"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d204723.5828516418!2d66.87731249915021!3d36.70319991023886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f34b8a3605314bd%3A0xb27a5e1a6c1fcf71!2z0JzQsNC30LDRgNC4LdCo0LDRgNC40YQsINCQ0YTQs9Cw0L3QuNGB0YLQsNC9!5e0!3m2!1sru!2s!4v1678116272855!5m2!1sru!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name7"),
      number: t("Contact_number7"),
      address: t("Contact_address7"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d204723.5828516418!2d66.87731249915021!3d36.70319991023886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f34b8a3605314bd%3A0xb27a5e1a6c1fcf71!2z0JzQsNC30LDRgNC4LdCo0LDRgNC40YQsINCQ0YTQs9Cw0L3QuNGB0YLQsNC9!5e0!3m2!1sru!2s!4v1678116272855!5m2!1sru!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name8"),
      number: t("Contact_number8"),
      address: t("Contact_address8"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d204723.5828516418!2d66.87731249915021!3d36.70319991023886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f34b8a3605314bd%3A0xb27a5e1a6c1fcf71!2z0JzQsNC30LDRgNC4LdCo0LDRgNC40YQsINCQ0YTQs9Cw0L3QuNGB0YLQsNC9!5e0!3m2!1sru!2s!4v1678116272855!5m2!1sru!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name9"),
      number: t("Contact_number9"),
      address: t("Contact_address9"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51201.33259030446!2d65.71716713399859!3d36.67249846627957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f36841a248302fd%3A0x5fdec4c1b4bcb0a6!2z0KjQuNCx0LDRgNCz0LDQvSwg0JDRhNCz0LDQvdC40YHRgtCw0L0!5e0!3m2!1sru!2s!4v1678114437912!5m2!1sru!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name10"),
      number: t("Contact_number10"),
      address: t("Contact_address10"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d102351.1756619311!2d68.81299242946497!3d36.711170103739335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38cbffa05e4f9665%3A0xb9fea05d9d615b22!2z0JrRg9C90LTRg9C3LCDQkNGE0LPQsNC90LjRgdGC0LDQvQ!5e0!3m2!1sru!2s!4v1678114751367!5m2!1sru!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name11"),
      number: t("Contact_number11"),
      address: t("Contact_address11"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52702.08638058019!2d62.17605046748124!3d34.353521874946686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f3ce6a894be6cf7%3A0x9db9f81752d677c4!2z0JPQtdGA0LDRgiwg0JDRhNCz0LDQvdC40YHRgtCw0L0!5e0!3m2!1sru!2s!4v1678116480886!5m2!1sru!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name12"),
      number: t("Contact_number12"),
      address: t("Contact_address12"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26601.52037818819!2d68.4032318728015!3d33.548438234524085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38d443e10b532347%3A0x364d5d3ca326a1a2!2z0JPQsNC30L3QuCwg0JDRhNCz0LDQvdC40YHRgtCw0L0!5e0!3m2!1sru!2s!4v1678116874855!5m2!1sru!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name13"),
      number: t("Contact_number13"),
      address: t("Contact_address13"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d108702.55446745976!2d65.68012903821025!3d31.635087191552174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ed671baa0e28837%3A0x2759b21c9042aa75!2z0JrQsNC90LTQsNCz0LDRgCwg0JDRhNCz0LDQvdC40YHRgtCw0L0!5e0!3m2!1sru!2s!4v1678116962035!5m2!1sru!2s" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name14"),
      number: t("Contact_number14"),
      address: t("Contact_address14"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55128.40725524224!2d-81.62057455888025!3d30.27909951308636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e5b5c1e33593c9%3A0x9b587022b8a6fddb!2z0JTQttC10LrRgdC-0L3QstC40LvQuywg0KTQu9C-0YDQuNC00LAgMzIyMTYsINCh0KjQkA!5e0!3m2!1sru!2s!4v1678185626972!5m2!1sru!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name15"),
      number: t("Contact_number15"),
      address: t("Contact_address15"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.144683300428!2d69.23658091506277!3d41.30571600901773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8ba9c7000001%3A0xb0c161ca741621d6!2zOCwgODY0USs5UFAsIDEgQnVueW9ka29yIEF2ZW51ZSwgVGFzaGtlbnQgMTAwMDM1LCDQo9C30LHQtdC60LjRgdGC0LDQvQ!5e0!3m2!1sru!2s!4v1678185750907!5m2!1sru!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name16"),
      number: t("Contact_number16"),
      address: t("Contact_address16"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11672.943537876332!2d68.85812038256317!3d42.99436736104147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aa006448422413%3A0x2cd91afb7427a0e7!2z0JDQu9GMLdCk0LDRgNCw0LHQuCwg0JrQsNC30LDRhdGB0YLQsNC9!5e0!3m2!1sru!2s!4v1678185862145!5m2!1sru!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    },
    {
      name: t("Contact_name17"),
      number: t("Contact_number17"),
      address: t("Contact_address17"),
      url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d199666.9572882386!2d68.64149518891266!3d38.56150890450092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38b5d16fd27bf89b%3A0xddf9378ddea1fd44!2z0JTRg9GI0LDQvdCx0LUsINCi0LDQtNC20LjQutC40YHRgtCw0L0!5e0!3m2!1sru!2s!4v1678185953777!5m2!1sru!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    }
  ]
  useEffect(() => {
    Aos.init()
    setMass(arr)
  }, [mass])



  return (
    <div className='big_content_contact'>
      <h1 className='text-center d-none d-lg-block my-5'>{t("Location")}</h1>
      <div className='context container row justify-content-center my-5'>
        {
          (mass) && mass.map((item, index) => {
            return (
              (Array.isArray(item)) ?
                <ul className='col-12 col-md-4 col-lg-6 p-0 mb-5'>
                  <div className='card card_back'>
                    <li><h2 className='name'>{item[0].name_main}</h2></li>
                    {
                      item[1].map((result, i) => {
                        return (
                          <div data-aos='fade-up' className='d-flex flex-column w-100 card_back' data-aos-duration='1000' key={i}>
                            <li className='main_item'>{result.name}</li>
                            <li>{result.number}</li>
                            <li className='text-lowercase mb-4'>{result.address}</li>
                            <li><iframe style={{ height: '300px', width: '100%' }} src={`${result.url}`} ></iframe></li>
                          </div>
                        )
                      })
                    }
                  </div>
                </ul>
                :
                <ul data-aos='fade-up' data-aos-duration='1000' className='col-12 col-md-4 col-lg-6 p-0 mb-5'>
                  {
                    <div className='card card_back'>
                      <li className='main_item'> {item.name}</li>
                      <li><i class="bi bi-telephone"></i> {item.number}</li>
                      <li style={{width: '100%'}} className='text-capitalize text-truncate address mb-2'><i class="bi bi-geo-alt"></i> {item.address}</li>
                      <li><iframe className='map' style={{ height: '300px', width: '100%' }} src={`${item.url}`} ></iframe></li>
                    </div>
                  }
                </ul>
            )
          })
        }
      </div>
    </div>
  )
}