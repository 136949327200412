import React from 'react';
import Headd from '../../../components/ui/ProductHeader'
import H1 from '../../../components/ui/H1/Header1'
import img3 from '../../../assets/sigir.png';
import img from '../../../assets/Mollar.png'
import Card from '../../../components/ui/AnimalCard'
import img1 from '../../../assets/chik1.png'


import bg from '../../../assets/HOmeImage/1.png'
import {useTranslation} from 'react-i18next'


const Index = () => {
    const {t} = useTranslation()
    const styles = {
        backgroundImage: `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        };
    const mass = [
        {
            image: img3,
            text: t("Animal_cow"),
            page: '/cow'
        },
        {
            image: img1,
            text: t("Animal_chicken"),
            page: '/hen'
        }
    ]
    return (
        <div style={styles}  >
            <Headd img={img} text={t("Animal_feed")} text1={t('Animal_feedd')} />
            <div className="container ">
                <H1 info={t("N_Products")} />
                <div>
                    <Card mass={mass}  />
                </div>
            </div>
        </div>
    );
}

export default Index;
