import React ,{useEffect}from 'react';
import './main1.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

const Index = (props) => {
    const {t} = useTranslation()

    useEffect(() => {
        AOS.init()
    }, []);

    function callMe(i){
        window.location.href = props.mass[i].page
        if(props.mass[i].image){
            localStorage.setItem('image', [props.mass[i].image1 , ])
        }
        localStorage.setItem('mass' , JSON.stringify(props.mass[i].massiv))
        localStorage.setItem('headerText' , [props.mass[i].text])
        localStorage.setItem('qop' , [props.mass[i].image2])
    }

    return (
        <div className='mainCard'>
            <div className='mainCard '>
                {
                    props.mass.map((item, index) => {
                        return (
                            <div key={index}  className="cardd" data-aos-duration='1000' data-aos={`${index % 2 === 0 ? 'fade-up': 'fade-down'}`} >
                                <Link onClick={()=>{callMe(index)}}>
                                    <div className='MYcard '  >
                                        <img src={item.image} alt="sigir" />
                                        <button>{t("See_more")}</button>
                                        <div className='botsec'>
                                            <p className='button_text'>{item.text}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Index;
