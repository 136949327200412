import React from 'react'
import Header from '../../components/ui/ProductHeader';
import img from '../../assets/Contact.png'
import Card from '../../components/ui/Contact/Contact'
import bg from '../../assets/HOmeImage/1.png'
import {useTranslation} from 'react-i18next'

export default function Index() {
  const {t} = useTranslation()
  const styles = {
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    };
  return (
    <div style={styles}>
     <Header img={img} text={t("Contact_title")}/>
     <Card/>
    </div>
  )
}
