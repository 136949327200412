import React, { useEffect } from 'react';
import '../AkfaAbout/main.css'
import name from '../../../assets/akfa.png';
import About from './about'
import AOS from 'aos';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination, Autoplay } from "swiper";
import image1 from '../../../assets/1.png'
import image2 from '../../../assets/2.png'
import image3 from '../../../assets/3.png'
import { useLocation, useNavigate } from 'react-router-dom'
import img from '../../../assets/About_us/about.png'
import Header from '../../../components/ui/ProductHeader/index';
import { useTranslation } from 'react-i18next'
import { useState } from 'react';


const Index = (props) => {
    const { t } = useTranslation()
    const akfa = JSON.parse(localStorage.getItem('akfa'));
    const [akwin7000, setAkwin7000] = useState(false)
    const [akwin6200, setAkwin6200] = useState(false)
    const [akwin6000, setAkwin6000] = useState(false)
    const [about , setAbout] = useState(akfa)


    const info = (localStorage.getItem('massiv'))
    const nimadir = info.split(',')
    


    useEffect(() => {
        if (nimadir[1] === t("Vinka_7000")) {
            setAkwin7000(true)
        } else if (nimadir[1] === t("Vinka_6200")) {
            setAkwin6200(true)
        } else if (nimadir[1] === t("Vinka_6000")) {
            setAkwin6000(true)
        }
        AOS.init()
    }, []);
    return (
        <div >
            {
                (akwin7000) && <Header img={img} text={t("Vinka_7000")}/>
            }
            {
                (akwin6200) && <Header img={img} text={t("Vinka_6200")}/>
            }
            {
                (akwin6000) && <Header img={img} text={t("Vinka_6000")}/>
            }
            <div className="container mt-3 m-auto">
                <h1 className='text-center text-light my-5'>
                    {
                        (akwin7000) && t("Vinka_7000")
                    }
                    {
                        (akwin6200) && t("Vinka_6200")
                    }
                    {
                        (akwin6000) && t("Vinka_6000")
                    }
                </h1>
                <div className='row firstSection m-auto'>
                    <div className="col-12 col-sm-12 col-md-4 imgSec" data-aos='zoom-in-down' data-aos-duration='1500'>
                        <img src={nimadir[0]} alt="akfa" />
                        <div className="line" data-aos="fade-down" data-aos-duration='2000'></div>
                        <div className="line1" data-aos="fade-up" data-aos-duration='2000'></div>
                        <div className="line2" data-aos="fade-down" data-aos-duration='2000'></div>
                        <div className="line3" data-aos="fade-up" data-aos-duration='2000'></div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-4 mt-5 mt-md-0 m-auto ulll' data-aos='zoom-in-up' data-aos-duration='1500'>
                        <h4 className='text-center'>{t("Pvc_detal")}</h4>
                        <ul>
                           {
                            (about) && about.map((item , index)=>{
                                return(
                                    <div key={index} >
                                        <li >{item.mas}</li>
                                    </div>
                                )
                            })
                           }
                        </ul>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4 mt-5 mt-md-0'>
                        <h4 className='myh4 text-center'>{t("Pvc_color")}</h4>
                        <Swiper
                            data-aos="fade-right"
                            data-aos-duration='2000'
                            effect={"coverflow"}
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView={"auto"}
                            coverflowEffect={{
                                rotate: 10,
                                stretch: 0,
                                depth: 100,
                                modifier: 2,
                                slideShadows: true,
                            }}
                            autoplay={{
                                delay: 5500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                              }}
                            modules={[EffectCoverflow, Pagination, Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <img src={image1} />
                                <p className='text-white text-center'>{t("Woder_color")}</p>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={image2} />
                                <p className='text-white text-center'>{t("White_color")}</p>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={image3} />
                                <p className='text-white text-center'>{t("Ocean_color")}</p>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                <div className='bottomline m-auto my-5'></div>
                <About />
            </div>
        </div>
    );
}

export default Index;
