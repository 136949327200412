import {Outlet} from 'react-router-dom'
import Nav from './components/ui/Navbar/Navbar'
import Footer from './components/ui/footer/Footer'
function App() {
  return (
    <div >
      <Nav/>
      <Outlet/>
      <Footer/>
    </div>
  );
}
export default App;

