import React from 'react';
import background_flour from '../../assets/flour/floor_header.png'
import Header from '../../components/ui/ProductHeader';
import Floor from '../../components/ui/Flour_section/Flour';
import img1 from '../../assets/HOmeImage/1.png'
import {useTranslation} from 'react-i18next'

const Index = () => {
    const {t} = useTranslation()
    const styles = {
        backgroundImage: `url(${img1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
        };
    return (
        <div style={styles}>
            <Header img={background_flour} text={t("Flour")}/>
            <Floor/>
        </div>
    );
}

export default Index;
