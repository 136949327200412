import React from 'react';
import bg from '../../../assets/HOmeImage/1.png'
import Card from '../../../components/ui/AnimalCard'
import H1 from '../../../components/ui/H1/Header1'
import img2 from '../../../assets/chik2.png'
import rasm from '../../../assets/joja.png'
import rasm1 from '../../../assets/tovuq.jpg'
import qop from '../../../assets/hensqop1.jpg'
import qop2 from '../../../assets/hensqop.jpg'
import img1 from '../../../assets/chik1.png'
import img from '../../../assets/Mollar.png'
import ProductHeader from '../../../components/ui/ProductHeader'
import { useTranslation } from 'react-i18next'
const Index = () => {
    const { t } = useTranslation()
    const styles = {
        backgroundImage: `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    };

    const mass = [
        {
            image: img1,
            image1: rasm1,
            image2: qop,
            text: t("Animal_hens"),
            massiv: [
                {
                    Name: t("FEATURES_milk"),
                    about: [{ i: t("Features_poultry1") },
                    { i: t("FEATURES_bull_4") },
                    { i: t("Features_poultry3") },
                    { i: t("Features_pultry4") },
                    ]

                },
                {
                    Name: t("BENEFITS_milk"),
                    about: [{ i: t("Benefits_pultry1") },
                    { i: t("Benefits_pultry2") },
                    { i: t("Benefits_pultry3") },
                    { i: t("Benefits_pultry4") },
                    { i: t("Benefits_pultry5") },
                    { i: t("Benefits_pultry6") }
                    ]
                },
                {
                    Name: t("INGREDIENTS_milk"),
                    about: [{ i: t("INGREDIENTS_milk_3") },
                    { i: t("INGREDIENTS_milk_6") },
                    { i: t("INGREDIENTS_milk_5") },
                    { i: t("Ingradients_poultry4") },
                    { i: t("Ingradients_poultry5") }]
                },
                {
                    Name: t("VITAMINS_milk"),
                    about: [{ i: t("VITAMINS_milk_1") },
                    { i: t("VITAMINS_milk_2") },
                    ]
                }
            ],
            page: '/animalabout'
        },
        {
            image: img2,
            image1: rasm,
            image2: qop2,
            text: t("Animal_broiler"),
            massiv: [
                {
                    Name: t("FEATURES_milk"),
                    about: [{ i: t("Features_pultry_broyler1") },
                    { i: t("FEATURES_bull_4") },
                    { i: t("Features_pultry_broyler3") }
                    ]

                },
                {
                    Name: t("BENEFITS_milk"),
                    about: [{ i: t("Benefist_pultry_broyler1") },
                    { i: t("Benefist_pultry_broyler2") },
                    { i: t("Benefist_pultry_broyler3") },
                    { i: t("Benefist_pultry_broyler4") },
                    { i: t("Benefist_pultry_broyler5") },
                    { i: t("Benefist_pultry_broyler6") },
                    { i: t("Benefist_pultry_broyler7") }
                    ]
                },
                {
                    Name: t("INGREDIENTS_milk"),
                    about: [{ i: t("INGREDIENTS_milk_3") },
                    { i: t("INGREDIENTS_milk_6") },
                    { i: t("INGREDIENTS_milk_5") },
                    { i: t("Ingradients_poultry4") },
                    { i: t("Ingradients_poultry5") },
                    { i: t("Ingradients_pultry_brouler6") }]
                },
                {
                    Name: t("VITAMINS_milk"),
                    about: [{ i: t("VITAMINS_milk_1") },
                    { i: t("VITAMINS_milk_2") },
                    ]
                }
            ],
            page: '/animalabout'
        }
    ]
    return (
        <div>
            <ProductHeader img={img} text={t("Poultry_feed")} />
            <div className="container" >
                <H1 info={t("N_Products")} />
                <div className="py-3" >
                    <Card mass={mass} />
                </div>
            </div>
        </div>
    );
}

export default Index;
