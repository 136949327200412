import React, { useState, useEffect } from 'react';
import ProductHeader from '../../../components/ui/ProductHeader'
import img from '../../../assets/cowheader.png'
import H1 from '../../../components/ui/H1/Header1'
import image2 from '../../../assets/sigir1.jpg'
import image1 from '../../../assets/gosht.jpg'
import qop from '../../../assets/cow.jpg'
import qop2 from '../../../assets/beefcow.jpg'
import img1 from '../../../assets/sigir.png'
import img2 from '../../../assets/meatCow.png'
import Card from '../../../components/ui/AnimalCard'
import { useTranslation } from 'react-i18next'

import bg from '../../../assets/HOmeImage/1.png'
const Index = () => {
    const { t } = useTranslation()
    const styles = {
        backgroundImage: `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    };

    const arr = [
        {
            image: img1,
            image1: image2,
            image2: qop2,
            text: t("Animal_Dair"),
            massiv: [
                {
                    Name: t("FEATURES_milk"),
                    about: [{ i: t("FEATURES_milk_1") },
                    { i: t("FEATURES_milk_2") },
                    { i: t("FEATURES_milk_3") },
                    { i: t("FEATURES_milk_4") }
                    ]

                },
                {
                    Name: t("BENEFITS_milk"),
                    about: [{ i: t("BENEFITS_milk_1") },
                    { i: t("BENEFITS_milk_2") },
                    { i: t("BENEFITS_milk_3") },
                    { i: t("BENEFITS_milk_4") },
                    { i: t("BENEFITS_milk_5") }
                    ]
                },
                {
                    Name: t("INGREDIENTS_milk"),
                    about: [{ i: t("INGREDIENTS_milk_1") },
                    { i: t("INGREDIENTS_milk_2") },
                    { i: t("INGREDIENTS_milk_3") },
                    { i: t("INGREDIENTS_milk_4") },
                    { i: t("INGREDIENTS_milk_5") },
                    { i: t("INGREDIENTS_milk_6") },
                    { i: t("INGREDIENTS_milk_7") },
                    { i: t("INGREDIENTS_milk_8") },
                    { i: t("INGREDIENTS_milk_9") },
                    { i: t("INGREDIENTS_milk_10") }
                    ]
                },
                {
                    Name: t("VITAMINS_milk"),
                    about: [{ i: t("VITAMINS_bull_1") },
                    { i: t("VITAMINS_bull_2") },
                    { i: t("VITAMINS_bull_3") }]
                }
            ],
            page: '/animalabout'
        },
        {
            image: img2,
            image1: image1,
            image2: qop,
            text: t("Animal_Cattle_bull"),
            massiv: [
                {
                    Name: t("FEATURES_milk"),
                    about: [{ i: t("FEATURES_bull_1") },
                    { i: t("FEATURES_bull_2") },
                    { i: t("FEATURES_bull_3") },
                    { i: t("FEATURES_bull_4") },
                    { i: t("FEATURES_bull_5") },
                    { i: t("FEATURES_bull_6") }
                    ]

                },
                {
                    Name: t("BENEFITS_milk"),
                    about: [{ i: t("BENEFITS_bull_1") },
                    { i: t("BENEFITS_bull_2") },
                    { i: t("BENEFITS_bull_3") },
                    { i: t("BENEFITS_bull_4") },
                    { i: t("BENEFITS_bull_5") },
                    { i: t("BENEFITS_bull_6") },
                    { i: t("BENEFITS_bull_7") },
                    { i: t("BENEFITS_bull_8") }
                    ]
                },
                {
                    Name: t("INGREDIENTS_milk"),
                    about: [{ i: t("INGREDIENTS_bull_1") },
                    { i: t("INGREDIENTS_bull_2") },
                    { i: t("INGREDIENTS_bull_3") },
                    { i: t("INGREDIENTS_bull_4") },
                    { i: t("INGREDIENTS_bull_5") },
                    { i: t("INGREDIENTS_bull_6") },
                    { i: t("INGREDIENTS_bull_7") },
                    { i: t("INGREDIENTS_bull_8") },
                    { i: t("INGREDIENTS_bull_9") }]
                },
                {
                    Name: t("VITAMINS_milk"),
                    about: [{ i: t("VITAMINS_bull_1") },
                    { i: t("VITAMINS_bull_2") },
                    { i: t("VITAMINS_bull_3") }
                    ]
                }
            ],
            page: '/animalabout'
        },
    ]

    const [mass, setMass] = useState([])

    useEffect(() => {
        setMass(arr)
    }, [mass])

    return (
        <div style={styles}>
            <ProductHeader img={img} text={t("Animal_cow")} />
            <div className="container" >
                <H1 info={t("N_Products")} />
                <div className="py-3" >
                    <Card mass={mass} />
                </div>
            </div>
        </div>
    );
}

export default Index;
