import React from 'react';
import Nav from '../../../components/ui/Navbar/Navbar'
import Footer from '../../../components/ui/footer/Footer'
import Header from '../../../components/ui/ProductHeader/index';
import About from '../../../components/ui/AkfaAbout/index'
import img from '../../../assets/About_us/about.png'
// import Anime from '../../../components/ui/akfaanime'
import img1 from '../../../assets/HOmeImage/1.png'
import Little from '../../../components/ui/littleContact'

const Index = () => {
    const styles = {
        backgroundImage: `url(${img1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
        };
    return (
        <div style={styles}>
                <About/>
            <div className='container'>
                <Little/>
            </div>
        </div>
    );
}

export default Index;
