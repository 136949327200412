import React, {useState, useEffect} from 'react';
import Header from '../../../components/ui/ProductHeader'
import img from '../../../assets/About_us/about.png'
import bg from '../../../assets/bg.png'
import bg1 from '../../../assets/c1.png'
import bg2 from '../../../assets/c2.png'
import bg3 from '../../../assets/c3.png'
import img0 from '../../../assets/akfa.png'
import img2 from '../../../assets/akfa1.png'
import img3 from '../../../assets/akfa3.png'
import img4 from '../../../assets/akfa4.png'
import one1 from '../../../assets/HOmeImage/1.png'
import Card from '../../../components/ui/AkfaAllProduc'
import {useTranslation} from 'react-i18next'

const Index = () => {
    const [malumot, setMalumot] = useState([])
    const {t} = useTranslation()
    const styles = {
        backgroundImage: `url(${one1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
        };
    const arr =[
        {
            bg: bg,
            img: img0,
            name: t("Vinka_7000"),
            about: t("Vinka_7000_about"),
            dec:[
                {
                    mas: t("Pvc_detal1_7000")
                },
                {
                    mas: t("Pvc_detal2_7000")
                },
                {
                    mas: t("Pvc_detal3_7000")
                },
                {
                    mas: t("Pvc_detal4_7000")
                },
                {
                    mas: t("Pvc_detal5_7000")
                },
                {
                    mas: t("Pvc_detal6_7000")
                },
                {
                    mas: t("Pvc_detal7_7000")
                }
            ],
            pass: '/akfaabout',
        },
        {
            bg: bg1,
            img: img3,
            name: t("Vinka_6200"),
            about: t("Vinka_6200_about"),
            dec:[
                {
                    mas: t("Pvc_detal1_6200")
                },
                {
                    mas: t("Pvc_detal2_6200")
                },
                {
                    mas: t("Pvc_detal3_6200")
                },
                {
                    mas: t("Pvc_detal4_6200")
                },
                {
                    mas: t("Pvc_detal5_6200")
                },
                {
                    mas: t("Pvc_detal6_6200")
                },
                {
                    mas: t("Pvc_detal7_6200")
                }
            ],
            pass: '/akfaabout',
        },
        {
            bg: bg2,
            img: img2,
            name: t("Vinka_6000"),
            about: t("Vinka_6000_about"),
            dec:[
                {
                    mas: t("Pvc_detal1_6000")
                },
                {
                    mas: t("Pvc_detal2_6000")
                },
                {
                    mas: t("Pvc_detal3_6000")
                },
                {
                    mas: t("Pvc_detal4_6000")
                },
                {
                    mas: t("Pvc_detal5_6000")
                },
                {
                    mas: t("Pvc_detal6_6000")
                },
                {
                    mas: t("Pvc_detal7_6000")
                }
            ],
            pass: '/akfaabout'
        },
        {
            bg: bg3,
            img: img4,
            name: t("Access"),
            about: t("Access_about"),
            pass: '/access'
        },
    ]

    useEffect(() => {
        setMalumot(arr)
    }, [t])
    


    return (             
        <div style={styles}>
            <Header img={img} text={t("About_us_title2")}/>
            <div className="container">
                <Card mass={malumot}/>
            </div>
        </div>
    );
}

export default Index;
