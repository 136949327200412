import React, { useEffect } from 'react';
import './Button.css'
import {useTranslation} from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'


const Button = (props) => {
    const {t} = useTranslation()
    const box = [
         props.images , 
         (props.names === t("Vinka_7000") ? t("Vinka_7000") : props.names === t("Vinka_6200") ? t("Vinka_6200") : t("Vinka_6000"))
    ]
    function callMe(){
        window.location.href = props.pass
        localStorage.setItem('akfa' , JSON.stringify(props.mass))
        localStorage.setItem('massiv', box)
       
    }


    return (
        <div>
            <div className='btn1'>
                <Link onClick={() => callMe()}   >{t("H_carousel_button")}</Link>
            </div>
        </div>
    );
}

export default Button;


