import React from 'react';

const style = {
    marginTop: '-100px'
}

const Index = (props) => {
    return (
        <div>
            <p style={style} className='text-center text-white py-3'>{props.info}</p>
        </div>
    );
}

export default Index;
