import React from 'react'
import img1 from '../../../assets/Iso_images/image11.jpg'
import img2 from '../../../assets/Iso_images/image14.jpg'
import img3 from '../../../assets/Iso_images/image17.png'
import img4 from '../../../assets/Iso_images/image18.png'
import './sertificat.css'
import ModalImage from "react-modal-image";
import { useTranslation } from 'react-i18next'

export default function Sertificat() {
  const {t} = useTranslation()
  return (
    <div className='statistics_about_us'>
      <div className='container text-center sertificats my-5'>
        <p className='iso__sertificats text-dark'>{t("ISO_Certificates")}</p>
        <div className="row  align-items-center px-5 px-lg-0">
          <div className="col-6 justify-content-center d-flex col-md-6 col-lg-3">
            <div className="card">
              <ModalImage
                small={img3}
                large={img3}
                alt={"Sertificate"}
                hideDownload={false}
                hideZoom={false}
                className="img-fluid modal-image"
              />
            </div>
          </div>
          <div className="col-6 justify-content-center d-flex my-2 my-lg-0 col-md-6 col-lg-3">
            <div className="card">
              <ModalImage
                small={img1}
                large={img1}
                alt={"Sertificate"}
                hideDownload={false}
                hideZoom={false}
                className="img-fluid modal-image"
              />
            </div>
          </div>
          <div className="col-6 justify-content-center d-flex my-2 my-lg-0 col-md-6 col-lg-3">
            <div className="card">
              <ModalImage
                small={img2}
                large={img2}
                alt={"Sertificate"}
                hideDownload={false}
                hideZoom={false}
                className="img-fluid modal-image"
              />
            </div>
          </div>
          <div className="col-6 justify-content-center d-flex my-4 my-lg-0 col-md-6 col-lg-3">
            <div className="card">
              <ModalImage
                small={img4}
                large={img4}
                alt={"Sertificate"}
                hideDownload={false}
                hideZoom={false}
                className="img-fluid modal-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

