import React, { useEffect } from 'react';
import img1 from '../../../../assets/ModalImge/img1.jpg'
import img2 from '../../../../assets/ModalImge/img2.jpg'
import img3 from '../../../../assets/ModalImge/img3.jpg'
import img4 from '../../../../assets/ModalImge/img4.jpg'
import img5 from '../../../../assets/ModalImge/img5.jpg'
import img6 from '../../../../assets/ModalImge/img6.jpg'
import img8 from '../../../../assets/ModalImge/img8.jpg'
import img7 from '../../../../assets/ModalImge/img7.jpg'
import img9 from '../../../../assets/ModalImge/img9.jpg'
import img10 from '../../../../assets/ModalImge/img10.jpg'
import img11 from '../../../../assets/ModalImge/img11.jpg'
import img12 from '../../../../assets/ModalImge/img12.jpg'
import img13 from '../../../../assets/ModalImge/img13.jpg'
import AOS from 'aos';
import './mainIndex.css'
import 'aos/dist/aos.css';
import ModalImage from "react-modal-image";
import Header1 from '../../H1/Header1'
import {useTranslation} from 'react-i18next'

const Index = () => {
    const {t} = useTranslation()
    useEffect(() => {
        AOS.init()
    }, []);

    const Images = [
        {
            imgUrl: img7,
            imgText: t("Profile_item_L")
        },


        {
            imgUrl: img1,
            imgText: t("Profile_item_L")
        },
        {
            imgUrl: img3,
            imgText: t("Profile_item_T")
        },
        {
            imgUrl: img8,
            imgText: t("Profile_item_Z")
        },
        {
            imgUrl: img9,
            imgText: t("Profile_item_T_7000")
        },
        {
            imgUrl: img2,
            imgText: t("Profile_item_T")
        },
        {
            imgUrl: img4,
            imgText: t("Profile_item_T")
        },

        {
            imgUrl: img5,
            imgText: t("Profile_double")
        },
        {
            imgUrl: img6,
            imgText: t("Profile_single")
        },
        {
            imgUrl: img10,
            imgText: t("Profile_double_7000")
        },
        {
            imgUrl: img11,
            imgText: t("Pvc_profile")
        },
        {
            imgUrl: img12,
            imgText: t("Box_profile")
        },
        {
            imgUrl: img13,
            imgText: t("Box_profile")
        },
        
    ]
    return (
        <div className='row  '>
            <Header1 info={t("Profile_info")} />
            {
                Images.map(img => {
                    return (
                        <>
                            <div className="col-6 col-sm-4 col-md-2 ">
                                <div className="bg-white img_card">
                                    <ModalImage
                                        small={img.imgUrl}
                                        large={img.imgUrl}
                                        alt={img.imgText}
                                        hideDownload={false}
                                        hideZoom={false}
                                        className="img-fluid modal-image"
                                    />
                                    <div className="">
                                        <hr />
                                        <h6>
                                            {img.imgText}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
            }
        </div>
    );
}

export default Index;