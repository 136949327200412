import React,{useState} from 'react';
import './main.css'
import Header1 from '../H1/Header1';
import img1 from '../../../assets/yem.png'
import {useTranslation} from 'react-i18next'

const Index = () => {
    const {t} = useTranslation()
const box = localStorage.getItem('image')
    const nimadir = box.split(',')
const qop = localStorage.getItem('qop')
    const qop1 = qop.split(',')
   
const [img, setimg] = useState(nimadir[0]);
const image =[
    {image:nimadir [0]},
    {image: img1},
    {image: qop1[0]}
]
    return (
        <div className="AnimalAboutDetalis">
            <div className='container'>
                <Header1 color='text-light'/>
                <div className="quti">
                    <div className=" mx-3 maincardd">
                        <img src={img} alt="aa"  className='w-100  ' style={{objectFit: 'contain'}}/>
                    </div>
                    <div>
                        <div>
                        {
                            image.map((item ,i)=>{
                                return <img src={item.image} alt="hahah" className={`${img ===  item.image ? 'cardd_feet carddd': 'cardd_feet'}`} onClick={()=>{setimg(item.image)}} />
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
