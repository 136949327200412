import React from 'react';
import img1 from '../../../assets/oli/oil1.jpg'
import './mian.css'

const Index = () => {
    return (
        <div className='container my-5'>
            <h1 className='text-center text-light my-2'>Vegetable oil</h1>
            <div className='row roow'>
                <img className='col-12 col-sm-6 col-md-6 img' src={img1} alt="oil" />
                <div className="col-12 col-sm-6 col-md-6 mt-5 mt-md-0">
                    <p className='text-light'>Vegetable oil is often a mix or a blend of different types of oils. It's a more generic type of oil that many people use in their everyday cooking. Vegetable oil is often an inexpensive choice that can be used for all kinds of cooking. And like canola oil, it has a neutral flavor.

                    The problem with this type of generic oil is that you're less likely to know exactly what's in your oil. This includes how the plants from which the oil was extracted were grown and how the oil was processed.

                    The ratio of saturated fat, polyunsaturated fat, and monounsaturated fat varies depending on what oils have been included in the blend (sunflower, corn, soy, safflower, etc.), so you won't have as much control over the types of fats you're eating.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Index;
