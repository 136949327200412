import React, { useState } from 'react';
import './main.css'

const Index = () => {
    const mal = JSON.parse(localStorage.getItem('mass'))
    const [about, setAbout] = useState(mal[0].about);
    return (
        <div className="container text-white parms">
            <div className='row d-flex align-items-top'>
                <div className="col-4 ">
                    {
                        mal.map((item, index) => {
                            return (
                                <div key={index} className='my-2 d-flex justify-content-start align-items-center'>
                                    <h4 className={`${about.length === mal[index].about.length ? 'text1' : 'text2'}`} onClick={() => { setAbout(mal[index].about) }}>{item.Name}</h4>
                                    <i className="bi bi-arrow-right fs-5 fw-2 mx-2"></i>
                                </div>
                            )
                        })
                    }

                </div>
                <div className='col-8 mt-3'>
                    <ul>
                        {
                            about.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <li className='li'>{item.i}</li>
                                    </div>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Index;
