import React,{useEffect ,useState} from 'react';
import Herder from '../../../components/ui/ProductHeader'
import Card from '../../../components/ui/AnimalDetalis'
import CardAbout from '../../../components/ui/AnimalAbout'
import Header1 from '../../../components/ui/H1/Header1';
import img from '../../../assets/Mollar.png'
import img1 from '../../../assets/HOmeImage/1.png'
import Little from '../../../components/ui/littleContact'
import {useTranslation} from 'react-i18next'
const Index = () => {
    const {t} = useTranslation()
    const [bull , setBull] = useState(false)
    const [cow , setCow] = useState(false)
    const [Hen , setHen] = useState(false)
    const [Broiler , setBroiler] = useState(false)

    const styles = {
        backgroundImage: `url(${img1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    
        }; 
        const HeadName = localStorage.getItem('headerText')

        useEffect(()=>{
            if(HeadName === t("Animal_Cattle_bull")){
                setBull(true)
            }else if(HeadName === t("Animal_Dair")){
                setCow(true)
            }else if(HeadName === t("Animal_broiler")){
                setBroiler(true)
            }else if(HeadName === t("Animal_hens")){
                setHen(true)
            }
        })
    return (
        <div style={styles}>
            {
                (bull) && <Herder img={img} text={t('Animal_Cattle_bull')}/>
            },
            {
                (cow) && <Herder img={img} text={t("Animal_Dair")}/>
            },
            {
                (Hen) && <Herder img={img} text={ t("Animal_hens")}/>
            },
            {
                (Broiler) && <Herder img={img} text={t("Animal_broiler")}/>
            }
            <div className='container'>
            <div className="text-center row gx-1">
                <div className='col-12 col-lg-6'>
                    <Card/>
                </div>
                <div className='col-12 col-lg-6 text-end '>
                    <Header1 info={t("Animal_about")}/>
                    <CardAbout/>
                </div>
            </div>
            </div>
            <div className='container'>
                <Little/>
            </div>
                
        </div>
    );
}

export default Index;
