import React from 'react';
import './main.css'
import {useTranslation} from 'react-i18next'

const Index = () => {
    const {t} = useTranslation()
    return (
        <div>
            <div className="conatiner my-4">
                <div className="row mycon px-3 px-lg-0">
                    <div className="col-12 col-sm-6 cardmy col-md-4 mt-md-0">
                        <i class="bi bi-geo-alt-fill text-light icons"></i>
                        <div>
                            <h4 className='text-light'>
                                {t("Little_contact_head")}
                            </h4>
                            <p style={{ width: '250px' }} className="text-truncate">
                                {t("Little_contact_desc")}
                            </p>
                        </div>
                    </div>
                    <a href='tel:+930794555552' className="col-12 col-sm-6 cardmy col-md-4 mt-md-0 ">
                        <i class="bi bi-telephone-fill text-light icons"></i>
                        <div>
                            <h4 className='text-light'>
                                {t("Call_us")}
                            </h4>
                            <p>
                                +93794110000
                            </p>
                        </div>
                    </a>
                    <div className="col-12 col-sm-6 cardmy col-md-4 mt-md-0 ">
                        <i class="bi bi-envelope-at-fill text-light icons"></i>
                        <div>
                            <h4 className='text-light'>
                                {t("Mail_us")}
                            </h4>
                            <p>
                                Parvazprof@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
