import React, { useState, useEffect } from 'react'
// import back_img from '../../../assets/About_us/About_us.png'
import akwin from '../../../assets/About_us/akwin.JPG'
import cow from '../../../assets/About_us/cow.png'
import factory from '../../../assets/About_us/factory.jpg'
import worker from '../../../assets/About_us/workers.JPG'
import 'aos/dist/aos.css'
import Aos from 'aos'
import './about_us.css'
import { useTranslation } from 'react-i18next'

export default function About_us() {
  const { t } = useTranslation()
  
  const arr = [
    {
      title: t("About_us_title1"),
      description: t("About_us_desc1"),
      description2: t("About_us_desc12"),
      description3: t("About_us_desc13"),
      src1: worker,
      src2: akwin
    },
    {
      title: t("About_us_title2"),
      description: t("About_us_desc2"),
      description2: t("About_us_desc21"),
      src1: factory,
      src2: cow
    }
  ]
  
  const [mass, setMass] = useState([]);
  
  useEffect(() => {
    Aos.init()
    setMass(arr)
  }, [mass]);

  return (
    <div className='big_content'>
      <div className="container">
        {
          (mass) && mass.map((item, index) => {
            return (
              <div className={`row d-flex align-items-center text-center my-5 ${(index % 2 === 1 ? 'content_top' : 'content_bottom')}`} key={index}>
                <div data-aos='fade-left' data-aos-duration='1000' className={`col-12 col-md-6 ${(index % 2 === 0 ? 'order-last' : 'order-first')}`}>
                  <p className='border-bottom border-2 border-md-3 w-100 my-2 my-md-5'></p>
                  <h4>{item.title}</h4>
                  <p className='px-3 text-start description1'>{item.description}</p>
                  <p className='px-3 text-start description1'>{item.description2}</p>
                  <p className='px-3 text-start description1'>{item.description3}</p>
                </div>
                <div className='col-12 col-md-6'>
                  <p data-aos='fade-down-right' data-aos-duration='1000'><img className='w-75 h-25' src={item.src1} alt={item.title} /></p>
                  <p data-aos='fade-up-right' data-aos-duration='1000'><img className='w-75 h-25' src={item.src2} alt={item.title} /></p>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
