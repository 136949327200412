import React from 'react';
import Oil from '../../components/ui/oil'
import Header from '../../components/ui/ProductHeader'
import img from '../../assets/HOmeImage/1.png'
import img1 from '../../assets/oli/oil.png'


const Index = () => {
    const styles = {
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    };
    return (
        <div style={styles}>
            <Header img={img1} text='Other Product' />
            <Oil/>
        </div>
    );
}

export default Index;
